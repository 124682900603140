/**
 * @flow
 */

const STRING_SEPARATOR = '.';
const NUMBER_SEPARATOR = '_';
const STRING_SPACE_REPLACEMENT = '-';

// See here: https://stackoverflow.com/questions/1144783/how-to-replace-all-occurrences-of-a-string-in-javascript
function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function encodeNumberSet(value: $ReadOnlySet<number>): string {
  return Array.from(value).join(NUMBER_SEPARATOR);
}

export function encodeStringSet(value: $ReadOnlySet<string>): string {
  return replaceAll(
    Array.from(value).join(STRING_SEPARATOR),
    ' ',
    STRING_SPACE_REPLACEMENT,
  );
}

export function decodeIntSet(
  value: ?string,
  allValues: ?$ReadOnlySet<number> = null,
): $ReadOnlySet<number> {
  if (value == null || value === '') {
    return new Set();
  }
  const split = value
    .split(NUMBER_SEPARATOR)
    .map((x) => parseInt(x))
    .filter((x) => !isNaN(x));
  return new Set(
    allValues == null ? split : split.filter((val) => allValues.has(val)),
  );
}

export function decodeStringSet(
  value: ?string,
  allValues: ?$ReadOnlySet<string> = null,
): $ReadOnlySet<string> {
  if (value == null || value === '') {
    return new Set();
  }
  const split = replaceAll(value, STRING_SPACE_REPLACEMENT, ' ').split(
    STRING_SEPARATOR,
  );
  return new Set(
    allValues == null ? split : split.filter((val) => allValues.has(val)),
  );
}

export function decodeEnumSet<TEnum: string>(
  value: ?string,
  enums: $ReadOnlySet<TEnum>,
): $ReadOnlySet<TEnum> {
  if (value == null || value === '') {
    return new Set();
  }
  const split = replaceAll(value, STRING_SPACE_REPLACEMENT, ' ').split(
    STRING_SEPARATOR,
  );
  const stringArrayFiltered = split.filter((entry) => enums.has((entry: any)));
  const enumArray = (stringArrayFiltered: any);
  return new Set(enumArray);
}
