/**
 * @flow
 */

import {
  decodeEnumSet,
  encodeStringSet,
  decodeStringSet,
} from 'src/utils/serialization';
import {ALL_MEDIUM_GROUPS} from 'src/dataUtils/imagerySchemaUtils';
import {IS_BROWSER} from 'src/constants';

import type {MediumGroup} from 'src/dataUtils/imagerySchemaUtils';

type MaybeQueryParams = $ReadOnly<{
  mediums?: ?$ReadOnlySet<MediumGroup>,
  collections?: ?$ReadOnlySet<string>,
}>;
export type QueryParams = $ReadOnly<{
  mediums: $ReadOnlySet<MediumGroup>,
  collections: $ReadOnlySet<string>,
}>;

export function readQueryParams(
  allCollections: ?$ReadOnlySet<string> = null,
): QueryParams {
  if (!IS_BROWSER) {
    return {
      mediums: new Set(),
      collections: new Set(),
    };
  }
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return {
    mediums: decodeEnumSet(params.mediums, ALL_MEDIUM_GROUPS),
    collections: decodeStringSet(params.collections, allCollections),
  };
}

export function writeQueryParams(queryParams: QueryParams): void {
  if (!IS_BROWSER) {
    return;
  }
  const {pathname, origin} = window.location;
  const url = `${origin}${pathname}${serializeQueryParams(queryParams)}`;
  window.history.replaceState(window.history.state, '', url);
}

export function serializeQueryParams(queryParams: MaybeQueryParams): string {
  const {mediums, collections} = queryParams;
  const config = {};
  // intentionally allow the situation where all of a particular filter are
  // selected, since these filtered fields can be null on the imagery, so
  // selecting all is not the same as selecting none
  if (mediums != null && mediums.size !== 0) {
    config.mediums = encodeStringSet((mediums: any));
  }
  if (collections != null && collections.size !== 0) {
    config.collections = encodeStringSet(collections);
  }
  const searchParams = new URLSearchParams(config);
  const searchParamsString = searchParams.toString();
  return searchParamsString === '' ? '' : '?' + searchParamsString;
}
