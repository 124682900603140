/**
 * @flow
 */

import * as React from 'react';
import {
  imageryComparator,
  getFilterByMediumGroup,
  getFilterByCollection,
  preprocessImageries,
} from 'src/dataUtils/imagerySchemaUtils';
import {
  readQueryParams,
  writeQueryParams,
} from 'src/dataUtils/imagerySearchQueryParamUtils';
import useForceUpdate from 'src/hooks/useForceUpdate';

import type {
  MediumGroup,
  ImageryWithoutImageData,
} from 'src/dataUtils/imagerySchemaUtils';
import type {QueryParams} from 'src/dataUtils/imagerySearchQueryParamUtils';

type Result<TImagery: ImageryWithoutImageData> = $ReadOnly<{
  allImageries: $ReadOnlyArray<TImagery>,
  filteredImageries: $ReadOnlyArray<TImagery>,
  updateQueryParams: ($Shape<QueryParams>) => void,
  clearQueryParams: () => void,
  queryParams: QueryParams,
  allMediumGroups: $ReadOnlySet<MediumGroup>,
  allCollections: $ReadOnlySet<string>,
}>;

export default function useImagerySearch<TImagery: ImageryWithoutImageData>(
  allImageriesUnsorted: $ReadOnlyArray<TImagery>,
): Result<TImagery> {
  const allImageries = React.useMemo(
    () => Array.from(allImageriesUnsorted).sort(imageryComparator),
    [allImageriesUnsorted],
  );
  const {allMediumGroups, allCollections} = React.useMemo(
    () => preprocessImageries(allImageries),
    [allImageries],
  );
  const queryParams = readQueryParams(allCollections);
  const forceUpdate = useForceUpdate();
  // TODO: idk if these useMemos are actually working...
  const filterByMediumGroup = React.useMemo(
    () => getFilterByMediumGroup(queryParams.mediums),
    [queryParams.mediums],
  );
  const filterByCollection = React.useMemo(
    () => getFilterByCollection(queryParams.collections),
    [queryParams.collections],
  );
  const totalFilter = React.useMemo(
    () =>
      (imagery: TImagery): boolean => {
        return filterByMediumGroup(imagery) && filterByCollection(imagery);
      },
    [filterByMediumGroup, filterByCollection],
  );
  const filteredImageries = React.useMemo(() => {
    const filtered = allImageries.filter(totalFilter);
    filtered.sort(imageryComparator);
    return filtered;
  }, [allImageries, totalFilter]);
  const updateQueryParams = React.useMemo(
    () => (updates) => {
      const next = {...queryParams, ...updates};
      writeQueryParams(next);
      forceUpdate();
    },
    [queryParams, forceUpdate],
  );
  const clearQueryParams = () =>
    updateQueryParams({
      mediums: new Set(),
      collections: new Set(),
    });
  return {
    allImageries,
    filteredImageries,
    updateQueryParams,
    clearQueryParams,
    queryParams,
    allMediumGroups,
    allCollections,
  };
}
