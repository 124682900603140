/**
 * @flow
 */

import * as React from 'react';
import {graphql} from 'gatsby';
import ImageryDetail from 'src/components/ImageryDetail';

import type {GatsbyGraphQL} from 'src/types/gatsby';
import type {
  Surface,
  DimensionsUnit,
  ImageryWithImageData,
} from 'src/dataUtils/imagerySchemaUtils';

type Props = $ReadOnly<{
  data: $ReadOnly<{
    contentfulImagery: ImageryWithImageData &
      $ReadOnly<{
        surface?: ?Surface,
        dimensionsUnit: DimensionsUnit,
        dimensionsWidth: number,
        dimensionsHeight: number,
      }>,
  }>,
}>;

export default function ImageryDetailPage(props: Props): React.Node {
  const {contentfulImagery} = props.data;
  return <ImageryDetail contentfulImagery={contentfulImagery} />;
}

/**
 * IMPORTANT: Image width was chosen to match theme.maxMainContentWidth
 */
export const query: GatsbyGraphQL = graphql`
  query ($id: String) {
    contentfulImagery(id: {eq: $id}) {
      ...SearchableImageryFragment
      surface
      dimensionsUnit
      dimensionsWidth
      dimensionsHeight
      images {
        gatsbyImageData(width: 800, formats: [AUTO, WEBP], placeholder: BLURRED)
      }
    }
  }
`;
