/**
 * @flow
 */

import * as React from 'react';
import {IS_BROWSER, NOOP} from 'src/constants';

const failedMatch = {matches: false, addListener: NOOP, removeListener: NOOP};
const matchMediaWithFallback = IS_BROWSER
  ? matchMedia
  : (...args) => failedMatch;

/**
 * NOTE: Adapted from these resources
 *   - https://usehooks.com/useMedia/
 *   - https://css-tricks.com/working-with-javascript-media-queries/
 *   - https://github.com/jaredLunde/react-hook/tree/master/packages/media-query#readme
 */
export default function useMediaQuery<TValue>(
  queries: $ReadOnlyArray<string>,
  values: $ReadOnlyArray<TValue>,
  defaultValue: TValue,
): TValue {
  const mediaQueryLists = queries.map((query) => matchMediaWithFallback(query));
  const getValue = () => {
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    const value = values[index];
    return typeof value !== 'undefined' ? value : defaultValue;
  };
  const [value, setValue] = React.useState(getValue);
  React.useEffect(
    () => {
      const handler = () => setValue(getValue());
      mediaQueryLists.forEach((mql) => mql.addListener(handler));
      return () =>
        mediaQueryLists.forEach((mql) => mql.removeListener(handler));
    },
    // Empty array ensures effect is only run on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return value;
}
