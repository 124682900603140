/**
 * @flow
 */

import {round} from 'src/utils/math';
import {graphql} from 'gatsby';

import type {GatsbyID, GatsbyImageData, GatsbyGraphQL} from 'src/types/gatsby';

/**
 * IMPORTANT: This needs to be kept in sync with contentful schema.
 */
export type Surface = 'STRETCHED_CANVAS' | 'CANVAS_PANEL' | 'PAPER';
/**
 * IMPORTANT: If you add more surface-less mediums (like digital ones), then you
 * need to update SURFACELESS_MEDIUMS.
 * IMPORTANT: This needs to be kept in sync with contentful schema.
 */
export type Medium =
  | 'OIL'
  | 'ACRYLIC'
  | 'INK'
  | 'CRAYON'
  | 'WATERCOLOR'
  | 'GOUACHE'
  | 'SOFTWARE'
  | 'PROCREATE'
  | 'PHOTOGRAPH'
  | 'DIGITAL'
  | 'PHOTOSHOP';
export type MediumGroup = 'PHYSICAL' | 'DIGITAL' | 'PHOTOGRAPH';
export const ALL_MEDIUM_GROUPS: $ReadOnlySet<MediumGroup> = new Set([
  'PHYSICAL',
  'DIGITAL',
  'PHOTOGRAPH',
]);
/**
 * IMPORTANT: This needs to be kept in sync with contentful schema.
 */
export type DimensionsUnit = 'INCHES' | 'CENTIMETERS' | 'PIXELS';
export type DimensionsInfo = $ReadOnly<{
  dimensionsUnit: DimensionsUnit,
  dimensionsWidth: number,
  dimensionsHeight: number,
}>;

export function getMediumGroup(medium: Medium): MediumGroup {
  switch (medium) {
    case 'OIL':
    case 'ACRYLIC':
    case 'INK':
    case 'CRAYON':
    case 'WATERCOLOR':
    case 'GOUACHE':
      return 'PHYSICAL';
    case 'PHOTOGRAPH':
      return 'PHOTOGRAPH';
    case 'PROCREATE':
    case 'PHOTOSHOP':
    case 'DIGITAL':
    case 'SOFTWARE':
      return 'DIGITAL';
    default:
      (medium: empty);
      return 'DIGITAL';
  }
}

export function prettyPrintDimensions({
  dimensionsUnit,
  dimensionsWidth,
  dimensionsHeight,
}: DimensionsInfo): ?string {
  return dimensionsUnit === 'PIXELS'
    ? null
    : `${dimensionsWidth} x ${dimensionsHeight} (${prettyPrintDimensionsUnit(
        dimensionsUnit,
      )})`;
}

export function prettyPrintDimensionsUnit(
  dimensionsUnit: DimensionsUnit,
): string {
  switch (dimensionsUnit) {
    case 'INCHES':
      return 'inches';
    case 'CENTIMETERS':
      return 'centimeters';
    case 'PIXELS':
      return 'pixels';
    default:
      (dimensionsUnit: empty);
      return '';
  }
}

export function prettyPrintSurface(surface: Surface): string {
  switch (surface) {
    case 'STRETCHED_CANVAS':
      return 'canvas';
    case 'CANVAS_PANEL':
      return 'canvas';
    case 'PAPER':
      return 'paper';
    default:
      (surface: empty);
      return 'a mysterious surface';
  }
}

export function prettyPrintMedium(medium: Medium): string {
  switch (medium) {
    case 'OIL':
      return 'Oil';
    case 'ACRYLIC':
      return 'Acrylic';
    case 'INK':
      return 'Ink';
    case 'GOUACHE':
      return 'Gouache';
    case 'WATERCOLOR':
      return 'Watercolor';
    case 'PROCREATE':
      return 'Digital';
    case 'PHOTOGRAPH':
      return 'Photograph';
    case 'PHOTOSHOP':
      return 'Digital';
    case 'DIGITAL':
      return 'Digital';
    case 'SOFTWARE':
      return 'Software';
    case 'CRAYON':
      return 'Crayon';
    default:
      (medium: empty);
      return 'A rather unusual medium';
  }
}

export function prettyPrintMediumGroup(mediumGroup: MediumGroup): string {
  switch (mediumGroup) {
    case 'PHYSICAL':
      return 'Physical';
    case 'DIGITAL':
      return 'Digital';
    case 'PHOTOGRAPH':
      return 'Photograph';
    default:
      (mediumGroup: empty);
      return 'A rather unusual medium';
  }
}

export function prettyPrintPrice(price: number): string {
  const fixedPrice = Number(price);
  if (isNaN(fixedPrice) || fixedPrice < 0) {
    return 'a shocking price';
  }
  return `$${round(fixedPrice)}`;
}

export function ensureProperImageryAlt(description: ?string): string {
  return description == null || description === ''
    ? 'an altogether stunning work of art'
    : description;
}

type Imagery<TExtraImageProps = {}> = $ReadOnly<{
  id: GatsbyID,
  urlPiece: string,
  gatsbyPath: string,
  title: string,
  description?: ?$ReadOnly<{
    description?: ?string,
  }>,
  medium: Medium,
  createdTimeYear?: ?number,
  createdTimeMonth?: ?number,
  createdTimeDay?: ?number,
  collections?: ?$ReadOnlyArray<
    $ReadOnly<{
      title: string,
    }>,
  >,
  images: $ReadOnlyArray<
    $ReadOnly<{
      ...TExtraImageProps,
      id: GatsbyID,
      description?: ?string,
    }>,
  >,
}>;
export type ImageryWithoutImageData = Imagery<>;
export type ImageryWithImageData = Imagery<
  $ReadOnly<{
    gatsbyImageData: GatsbyImageData,
  }>,
>;

export const imageryFragment: GatsbyGraphQL = graphql`
  fragment SearchableImageryFragment on ContentfulImagery {
    id
    urlPiece
    gatsbyPath(filePath: "/imagery/gallery/{ContentfulImagery.urlPiece}")
    title
    description {
      description
    }
    medium
    createdTimeYear
    createdTimeMonth
    createdTimeDay
    collections {
      title
    }
    images {
      id
      description
    }
  }
`;

export function imageryComparator(
  imageryA: ImageryWithoutImageData,
  imageryB: ImageryWithoutImageData,
): number {
  const yearA = imageryA.createdTimeYear ?? 0;
  const yearB = imageryB.createdTimeYear ?? 0;
  if (yearA !== yearB) {
    return yearB - yearA;
  }
  const monthA = imageryA.createdTimeMonth ?? 0;
  const monthB = imageryB.createdTimeMonth ?? 0;
  if (monthA !== monthB) {
    return monthB - monthA;
  }
  const dayA = imageryA.createdTimeDay ?? 0;
  const dayB = imageryB.createdTimeDay ?? 0;
  if (dayA !== dayB) {
    return dayB - dayA;
  }
  const titleA = imageryA.title.toUpperCase();
  const titleB = imageryB.title.toUpperCase();
  return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
}

export function getFilterByMediumGroup(
  selectedMediumGroups: $ReadOnlySet<MediumGroup>,
): (ImageryWithoutImageData) => boolean {
  return (imagery: ImageryWithoutImageData): boolean => {
    if (selectedMediumGroups.size === 0) {
      return true;
    }
    return selectedMediumGroups.has(getMediumGroup(imagery.medium));
  };
}

export function getFilterByCollection(
  selectedCollections: $ReadOnlySet<string>,
): (ImageryWithoutImageData) => boolean {
  return (imagery: ImageryWithoutImageData): boolean => {
    if (selectedCollections.size === 0) {
      return true;
    }
    for (const collection of imagery.collections ?? []) {
      if (selectedCollections.has(collection.title)) {
        return true;
      }
    }
    return false;
  };
}

type PreprocessedImageriesInfo = $ReadOnly<{
  allCollections: $ReadOnlySet<string>,
  allMediumGroups: $ReadOnlySet<MediumGroup>,
}>;
export function preprocessImageries(
  imageries: $ReadOnlyArray<ImageryWithoutImageData>,
): PreprocessedImageriesInfo {
  const allCollections = new Set();
  const allMediumGroups = new Set();
  for (const imagery of imageries) {
    allMediumGroups.add(getMediumGroup(imagery.medium));
    const imageryCollections = imagery.collections ?? [];
    for (const collection of imageryCollections) {
      allCollections.add(collection.title);
    }
  }
  return {
    allCollections,
    allMediumGroups,
  };
}
