/**
 * @flow
 */

import * as React from 'react';

import {GatsbyImage} from 'gatsby-plugin-image';
import {ensureProperImageryAlt} from 'src/dataUtils/imagerySchemaUtils';
import {useTheme} from '@emotion/react';

import type {ImageryWithImageData} from 'src/dataUtils/imagerySchemaUtils';

type Props = $ReadOnly<{
  images: ImageryWithImageData['images'],
}>;

const THUMBNAIL_SIZE = 80;
const styles = {
  featureImage: (theme, hasThumbnails) => ({
    maxHeight: `calc(100vh ${hasThumbnails ? `- ${THUMBNAIL_SIZE}px ` : ''}- ${
      1.5 * theme.pagePadding + 2 * theme.thumbnailPadding
    }rem)`,
    marginBottom: `${theme.pagePadding / 2}rem`,
  }),
  imagesList: (theme) => ({
    listStyleType: 'none',
    display: 'flex',
    marginBottom: `${theme.pagePadding / 2}rem`,
  }),
  imagesListItemSelected: (theme) => ({
    paddingBottom: `${theme.thumbnailPadding}rem`,
    // needed for the :after to position absolutely to
    position: 'relative',
    ':after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: `${theme.thumbnailPadding}rem`,
      background: theme.linkColor,
    },
  }),
  imageThumbnailButton: (theme) => ({
    border: 0,
    boxSizing: 'content-box',
    padding: `${theme.thumbnailPadding}rem`,
    width: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE,
    cursor: 'pointer',
    ':hover': {
      background: theme.linkHoverColor,
    },
  }),
  imageThumbnail: {
    height: THUMBNAIL_SIZE,
    width: THUMBNAIL_SIZE,
  },
};

export default function MultiImage(props: Props): React.Node {
  const {images} = props;
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const theme = useTheme();
  if (images.length === 0) {
    return null;
  }
  const selectedImage = images[selectedIndex];
  const hasThumbnails = images.length > 1;
  return (
    <div>
      <GatsbyImage
        loading='eager'
        objectFit='contain'
        image={selectedImage.gatsbyImageData}
        style={styles.featureImage(theme, hasThumbnails)}
        alt={ensureProperImageryAlt(selectedImage.description)}
      />
      <ul css={styles.imagesList}>
        {hasThumbnails &&
          images.map((image, idx) => (
            <li
              key={image.id}
              css={idx === selectedIndex ? [styles.imagesListItemSelected] : []}
            >
              <button
                css={styles.imageThumbnailButton}
                onClick={() => {
                  if (idx !== selectedIndex) {
                    setSelectedIndex(idx);
                  }
                }}
              >
                <GatsbyImage
                  image={image.gatsbyImageData}
                  style={styles.imageThumbnail}
                  alt={ensureProperImageryAlt(image.description)}
                />
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
}
