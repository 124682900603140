/**
 * @flow
 */

import * as React from 'react';

type Props = $ReadOnly<{
  // intentionally require year because otherwise this renders null. calling
  // code is responsible for that because it was more convinient to link to
  // imagery search filter that way. might not be anymore, but whatever.
  year: number,
  month: ?number,
  day?: ?number,
}>;

export default function Time(props: Props): React.Node {
  const {year, month, day, ...passedProps} = props;
  const timeStr = getTimeStr(props);
  return (
    <time {...passedProps} dateTime={timeStr}>
      {timeStr}
    </time>
  );
}

function getTimeStr({year, month, day}: Props): string {
  if (month == null) {
    return `${year}`;
  }
  if (day == null) {
    return `${year}-${month}`;
  }
  return `${year}-${month}-${day}`;
}
