/**
 * @flow
 */

import * as React from 'react';
import Page from 'src/components/Page';
import Time from 'src/components/Time';
import {graphql, useStaticQuery} from 'gatsby';
import MultiImage from 'src/components/MultiImage';
import {
  prettyPrintMedium,
  prettyPrintSurface,
  prettyPrintDimensions,
  getMediumGroup,
} from 'src/dataUtils/imagerySchemaUtils';
import InternalLink from 'src/components/navigation/InternalLink';
import {serializeQueryParams} from 'src/dataUtils/imagerySearchQueryParamUtils';
import {linkStyle, visuallyHiddenStyle} from 'src/styles/common';
import useImagerySearch from 'src/hooks/useImagerySearch';
import {ArrowLeftIcon, ArrowRightIcon} from 'src/components/icons/Icons';
import {getWithBreakpoints} from 'src/styles/breakpoints';
import useMediaQuery from 'src/hooks/useMediaQuery';
import {IMAGERY_OG_IMAGE} from 'src/constants';

import type {GatsbyID} from 'src/types/gatsby';
import type {
  Surface,
  DimensionsUnit,
  ImageryWithoutImageData,
  ImageryWithImageData,
} from 'src/dataUtils/imagerySchemaUtils';

type Props = $ReadOnly<{
  contentfulImagery: ImageryWithImageData &
    $ReadOnly<{
      surface?: ?Surface,
      dimensionsUnit: DimensionsUnit,
      dimensionsWidth: number,
      dimensionsHeight: number,
    }>,
}>;

// 500 was picked just because it looked good
const withBreakpoint = getWithBreakpoints([500]);
const DETAIL_GROUP_SPACING = '1.6rem';
const styles = {
  topBarRoot: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    marginBottom: `${theme.pagePadding}rem`,
  }),
  prevAndNextLink: linkStyle,
  arrowButton: {
    padding: 8,
  },
  editSearchLink: (theme) =>
    withBreakpoint({
      ...linkStyle(theme),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
  filterInfo: {
    fontWeight: 'bold',
  },
  details: (theme) => ({
    padding: `${theme.pagePadding}rem`,
    background: theme.unshadeColor,
  }),
  title: (theme) => ({
    fontSize: `${theme.fontSizeLarge}rem`,
    fontWeight: 'normal',
  }),
  statsList: {
    listStyleType: 'none',
    paddingTop: DETAIL_GROUP_SPACING,
    lineHeight: 1.4,
  },
  statsListLink: linkStyle,
  description: {
    marginTop: DETAIL_GROUP_SPACING,
  },
};

type PrevAndNextButtonBarProps = $ReadOnly<{
  id: GatsbyID,
  allImageries: $ReadOnlyArray<ImageryWithoutImageData>,
  filteredImageries: $ReadOnlyArray<ImageryWithoutImageData>,
}>;
function PrevAndNextButtonBar(props: PrevAndNextButtonBarProps): React.Node {
  const {id, filteredImageries, allImageries} = props;
  const numResults = filteredImageries.length;
  const numTotal = allImageries.length;
  let imageriesToCycle = filteredImageries;
  let preserveQueryParams = true;
  if (numResults <= 1) {
    // clear filters and go to next of all imageries
    preserveQueryParams = false;
    imageriesToCycle = allImageries;
  }
  const currentImageryIdx = imageriesToCycle.findIndex(
    (imagery) => imagery.id === id,
  );
  let nextImageryIdx = null;
  let prevImageryIdx = null;
  if (currentImageryIdx === -1) {
    nextImageryIdx = 0;
    prevImageryIdx = 0;
  } else {
    nextImageryIdx = currentImageryIdx + 1;
    if (nextImageryIdx >= imageriesToCycle.length) {
      nextImageryIdx = 0;
    }
    prevImageryIdx = currentImageryIdx - 1;
    if (prevImageryIdx < 0) {
      prevImageryIdx = imageriesToCycle.length - 1;
    }
  }
  const nextImagery = imageriesToCycle[prevImageryIdx];
  const prevImagery = imageriesToCycle[nextImageryIdx];
  const arrowLabelStyle = useMediaQuery(
    // 410 was chosen just because it looked nice
    ['(min-width: 410px)'],
    [{}],
    visuallyHiddenStyle,
  );
  return (
    <div css={styles.topBarRoot}>
      <InternalLink
        css={styles.prevAndNextLink}
        to={prevImagery.gatsbyPath}
        preserveQueryParams={preserveQueryParams}
      >
        <span css={styles.arrowButton}>
          <ArrowLeftIcon />
        </span>
        <span css={arrowLabelStyle}>Previous</span>
      </InternalLink>
      <InternalLink
        css={styles.editSearchLink}
        to='/imagery/'
        preserveQueryParams={true}
      >
        <span>Edit search</span>
        {numResults !== numTotal && (
          <span css={styles.filterInfo}>
            (filtered to {numResults} / {numTotal})
          </span>
        )}
      </InternalLink>
      <InternalLink
        css={styles.prevAndNextLink}
        to={nextImagery.gatsbyPath}
        preserveQueryParams={preserveQueryParams}
      >
        <span css={arrowLabelStyle}>Next</span>
        <span css={styles.arrowButton}>
          <ArrowRightIcon />
        </span>
      </InternalLink>
    </div>
  );
}

export default function ImageryDetail(props: Props): React.Node {
  const {contentfulImagery} = props;
  const {
    id,
    urlPiece,
    title,
    description: descriptionInfo,
    createdTimeYear,
    createdTimeMonth,
    medium,
    surface,
    dimensionsUnit,
    dimensionsWidth,
    dimensionsHeight,
    images,
    collections,
  } = contentfulImagery;
  const staticData: $ReadOnly<{
    allContentfulImagery: $ReadOnly<{
      nodes: $ReadOnlyArray<ImageryWithImageData>,
    }>,
  }> = useStaticQuery(graphql`
    query {
      allContentfulImagery {
        nodes {
          ...SearchableImageryFragment
        }
      }
    }
  `);
  const {allImageries, filteredImageries} = useImagerySearch(
    staticData.allContentfulImagery.nodes,
  );
  const description = descriptionInfo?.description;
  const dimensionsMarkup = prettyPrintDimensions({
    dimensionsUnit,
    dimensionsWidth,
    dimensionsHeight,
  });
  const createdTimeMarkup =
    createdTimeYear == null ? null : (
      <Time year={createdTimeYear} month={createdTimeMonth} />
    );
  const statsListItems = [
    <InternalLink
      css={styles.statsListLink}
      to={`/imagery/${serializeQueryParams({
        mediums: new Set([getMediumGroup(medium)]),
      })}`}
    >
      {prettyPrintMedium(medium)}
      {surface != null && ' on ' + prettyPrintSurface(surface)}
    </InternalLink>,
  ];
  if (dimensionsMarkup != null) {
    statsListItems.push(dimensionsMarkup);
  }
  if (createdTimeMarkup != null) {
    statsListItems.push(createdTimeMarkup);
  }
  if (collections != null && collections.length !== 0) {
    statsListItems.push(
      <React.Fragment>
        Part of{' '}
        {collections.map((collection, idx) => (
          <React.Fragment key={idx}>
            <InternalLink
              css={styles.statsListLink}
              to={`/imagery/${serializeQueryParams({
                collections: new Set([collection.title]),
              })}`}
            >
              {collection.title}
            </InternalLink>
            {idx < collections.length - 1 && ', '}
          </React.Fragment>
        ))}
      </React.Fragment>,
    );
  }
  return (
    <Page
      title={title}
      canonicalPath={`/imagery/gallery/${urlPiece}/`}
      description={`"${title}" - Art by Southerfields${
        description == null ? '' : `: ${description}`
      }`}
      ogImage={IMAGERY_OG_IMAGE}
    >
      <PrevAndNextButtonBar
        id={id}
        filteredImageries={filteredImageries}
        allImageries={allImageries}
      />
      <article>
        <MultiImage images={images} />
        <div css={styles.details}>
          <h2 css={styles.title}>{title}</h2>
          <ul css={styles.statsList}>
            {statsListItems.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
          {description != null && (
            <div css={styles.description}>{description}</div>
          )}
        </div>
      </article>
    </Page>
  );
}
